import CardList from "app/components/CardList";
import ClassCard from "app/components/ClassCard";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import React from "react";
import { Link } from "react-router-dom";
import { Class } from "services/graphql";
import { Waypoint } from "app/components/Waypoint";
import { useGetSavedClasses } from "./hooks";

export function SavedClasses() {
  const {
    isFetchingInitialSavedClasses,
    savedClasses,
    loadingSavedClasses,
    fetchMoreSavedClasses,
    pageInfoSavedClasses,
  } = useGetSavedClasses();

  if (isFetchingInitialSavedClasses) {
    return <LoaderCentered />;
  }

  return savedClasses?.length > 0 ? (
    <CardList
      mt={2}
      cards={savedClasses}
      sentinel={
        !loadingSavedClasses && (
          <Waypoint
            hasMore={pageInfoSavedClasses.hasNextPage}
            fetchData={fetchMoreSavedClasses}
          />
        )
      }
      renderCard={({ cardData }: { cardData: Class }) => (
        <ClassCard
          key={cardData.id}
          data={cardData}
          selectedFrom="SavedClasses"
        />
      )}
    />
  ) : (
    <span>
      You don&apos;t have any saved classes. Search for{" "}
      <Link to="/library/classes">classes</Link> and click the heart icon to
      save them!
    </span>
  );
}
